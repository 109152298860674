<template>
  <div>
    <div class="card p-1">
      <div class="h2 col-12" style="color:rgb(85, 140, 239">ทะเบียนคุมทรัพย์สิน</div>
      <hr />
      <div class="row justify-content-between">
        <div class="col-12 col-md-6">
          <b-overlay :show="show" rounded="sm">
            <vue-good-table :columns="columns" :rows="rows" @on-cell-click="onCellClick" :line-numbers="true" />
          </b-overlay>
        </div>
        <div class="col-12 col-md-6">
          <div>
            <b-form-group label="เลขทะเบียน" label-for="h-first-name" label-cols-md="4">
              <b-input id="h-first-name" v-model="equipment_code" placeholder="เลขทะเบียน" disabled />
            </b-form-group>
            <b-form-group label="ชื่อทะเบียน" label-for="h-first-name" label-cols-md="4">
              <b-form-input id="h-first-name" v-model="register_name" placeholder="ชื่อทะเบียน" disabled />
            </b-form-group>
            <hr />
            <div>
              <b-form-group label="ช่องที่ 1 (กำหนดเอง) " label-for="h-first-name" label-cols-md="4">
                <template v-slot:label> ช่องที่ 1 (กำหนดเอง) <span class="text-danger"> *</span> </template>
                <b-form-input id="h-first-name" v-model="equipment_code" placeholder="" />
              </b-form-group>
              <b-form-group label="ช่องที่ 2 (Auto)" label-for="h-first-name" label-cols-md="4">
                <template v-slot:label> ช่องที่ 2 (Auto) <span class="text-danger"> *</span> </template>
                <b-form-input id="h-first-name" v-model="code_number" placeholder="001" disabled />
              </b-form-group>
              <b-form-group label="ช่องที่ 3 (ปีงบประมาณ)" label-for="h-first-name" label-cols-md="4">
                <template v-slot:label> ช่องที่ 3 (ปีงบประมาณ) <span class="text-danger"> *</span> </template>
                <b-form-input
                  type="text"
                  id="h-first-name"
                  v-model="budget_year"
                  placeholder="ปีงบประมาณ"
                  :maxlength="max"
                />
              </b-form-group>

              <hr />
              <div>
                <b-form-group label="เลขทะเบียนล่าสุด" label-for="h-first-name" label-cols-md="4">
                  <b-form-input id="h-first-name" v-model="code_number" placeholder="เลขทะเบียนล่าสุด" />
                </b-form-group>
                <hr />
                <div>
                  <b-button variant="success" @click="get_material"> ตกลง </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import API from '@/views/connectDB/condb.js'

export default {
  components: {
    VueGoodTable,
    BButton,
    vSelect,
  },
  data() {
    return {
      show: false,
      max: '4',
      equipment_code: '',
      register_name: '',
      code_number: '',
      budget_year: '',

      columns: [
        { label: 'รหัส', field: 'equipment_code' },
        { label: 'ชื่อทะเบียน', field: 'register_name' },
      ],
      rows: [],
    }
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg
      },
    },
  },
  async created() {
    await this.material_list()
  },
  methods: {
    onCellClick(params) {
      console.log(params.row)
      const { equipment_code, register_name, code_number, budget_year } = params.row
      ;(this.equipment_code = equipment_code),
        (this.register_name = register_name),
        (this.code_number = code_number),
        (this.budget_year = budget_year)
      // params.row - row object
      // params.column - column object
      // params.rowIndex - index of this row on the current page.
      // params.event - click event
    },
    async get_material() {
      const { access_token } = await this.access_token()
      var equipmentCodes = {
        equipment_code: this.equipment_code,
        register_name: this.register_name,
        code_number: this.code_number,
        budget_year: this.budget_year,
      }

      axios
        .put(API + 'equipmentCodes', equipmentCodes, {
          headers: {
            authorization: access_token,
          },
        })
        .then((response) => {
          console.log(response)
          if (response.data.status == 200) {
            Swal.fire({
              position: 'top-center',
              icon: 'success',
              title: 'บันทึกข้อมูล',
              showConfirmButton: false,
              timer: 1500,
            })
            this.material_list()
          } else {
            Swal.fire({
              position: 'top-center',
              icon: 'error',
              title: response.data.message,
              showConfirmButton: false,
              timer: 1500,
            })
          }
        })
        .catch((error) => {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: error.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
          console.log(error)
        })
    },
    async material_list() {
      const { access_token } = await this.access_token()
      this.rows = []
      this.total = ''
      this.show = true
      axios
        .get(API + 'equipmentCodes?_page=1&_limit=100&_sort=1', {
          headers: {
            authorization: access_token,
          },
        })
        .then((response) => {
          console.log(response)
          console.log('asdsadsasadsa')
          this.rows = response.data.message.data
          this.total = response.data.message.total
          this.show = false
        })
        .catch((error) => {
          console.log(error)
        })
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message
    },
  },
}
</script>

<style></style>
